import map from '../pages/map.vue'
import landing from '../pages/landing.vue'

import NotFoundPage from '../pages/404.vue';

var routes = [
  {
    path: '/landing',
    component: landing,
  },
  {
    path: '/map',
    component: map,
  },
  
  {
    path: '(.*)',
    component: NotFoundPage,
  },
];

export default routes;
