import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
export const store =new Vuex.Store({
state:{
    typeOfResources:{},//tipos de recursos
    resourceTypeEnFoco: null,//para creacion oo edicion de tipo de recurso
    gmapsAPIkey: 'AIzaSyAXB_J_9zZEnNzptUPwM2u9LK2APMwMuog',
    resources:{},//list of all available resources
    clinic:{},
    clinicid:null,
    patientid:null,
    lastAccess:null,
    resourceTypesIcons:[
        {ico:'american-sign-language-interpreting',uni:'\uf2a3'},
        {ico:'apple',uni:'\uf179'},
        {ico:'bicycle',uni:'\uf206 '},
        {ico:'binoculars',uni:'\uf1e5'},
        {ico:'birthday-cake',uni:'\uf1fd'},
        {ico:'building',uni:'\uf1ad'},
        {ico:'child',uni:'\uf1ae'},
        {ico:'cutlery',uni:'\uf0f5'},
        {ico:'dribbble',uni:'\uf17d'},
        {ico:'flag-checkered',uni:'\uf11e'},
        {ico:'futbol-o',uni:'\uf1e3'},
        {ico:'gamepad',uni:'\uf11b'},
        {ico:'gift',uni:'\uf06b'},
        {ico:'graduation-cap',uni:'\uf19d'},
        {ico:'heart',uni:'\uf004'},
        {ico:'heartbeat',uni:'\uf21e'},
        {ico:'leaf',uni:'\uf06c'},
        {ico:'lemon-o',uni:'\uf094'},
        {ico:'medkit',uni:'\uf0fa'},
        {ico:'music',uni:'\uf001'},
        {ico:'paw',uni:'\uf1b0'},
        {ico:'shopping-bag',uni:'\uf290'},
        {ico:'shopping-basket',uni:'\uf291'},
        {ico:'shopping-cart',uni:'\uf07a'},
        {ico:'tree', uni: '\uf1bb' },
        {ico:'trophy',uni:'\uf091'},
        {ico:'universal-access',uni:'\uf29a'},
        {ico:'university',uni:'\uf19c'},
        {ico:'users',uni:'\uf0c0'},
        {ico:'wikipedia-w',uni:'\uf266'},
        {ico:'product-hunt',uni:'\uf288'},
        {ico:'user-md',uni:'\uf0f0'},
        {ico:'archive',uni:'\uf187'},
        {ico:'handshake-o',uni:'\uf2b5'},
        {ico:'lightbulb-o',uni:'\uf0eb'}
     ],
     actualPatient:null,
     focusedPrescription:{}
},
getters:{
    sortedResources:state=>{
        return Object.values(state.resources).sort((a,b)=>{
            var x = a.ginfo.name.toLowerCase();
            var y = b.ginfo.name.toLowerCase();
            if (x < y) {return -1;}
            if (x > y) {return 1;}
            return 0;
        });
    },
    sortedResourcesTypes:state=>{
        return Object.values(state.typeOfResources).sort((a,b)=>{
            var x = a.name.toLowerCase();
            var y = b.name.toLowerCase();
            if (x < y) {return -1;}
            if (x > y) {return 1;}
            return 0;
        });
    },
},
mutations:{
    setSelection:(state,payload)=>{
        let prevs=Object.assign({},state.focusedPrescription[payload.targetstr] || {});
        if(payload.delete){
            delete prevs[payload.item.id];
        }
        else{
            prevs[payload.item.id]=payload.item.id;
        }
        state.focusedPrescription[payload.targetstr]=Object.assign({},prevs);
    },
    setWhatTo:(state,payload)=>{
        state[payload.what]=payload.to;
    },
    setObjectTo:(state,payload)=>{
        if(payload.to==null){
            state[payload.what]=payload.to;
        }
        else{
            state[payload.what]=Object.assign({},payload.to);
        }
    },
    updateOpeningHoursToResource:(state,payload)=>{
        if(state.resources[payload.id]){
            state.resources[payload.id]=Object.assign({},state.resources[payload.id],{opening_hours:payload.value});
        }
    }

}
})