<template>
  <f7-app :params="f7params" >
  <!-- Your main view, should have "view-main" class -->
  <f7-view main class="safe-areas" url="/landing"></f7-view>
  </f7-app>
</template>
<script>
import { mapState } from 'vuex';

  import routes from '../js/routes.js';
  export default {
    data() {
      return {
        // Framework7 Parameters
        f7params: {
          name: 'prevent-resources-map', // App name
          theme: 'auto', // Automatic theme detection



          // App routes
          routes: routes,
        },
      }
    },
    computed:{
      ...mapState(["clinicid","patientid"])
    },
    methods: {
      async getInfo(){
        try{
          
          if(!this.clinicid){
          let storedClinicid= await this.$localForage.getItem('clinicid')
          this.$store.commit('setWhatTo',{what:'clinicid',to:storedClinicid || null})
          
        }else{
           this.$localForage.setItem('clinicid',this.clinicid)
        }
        if(!this.patientid){
          let storedPatientid= await this.$localForage.getItem('patientid')
          this.$store.commit('setWhatTo',{what:'patientid',to:storedPatientid || null})
        }else{
          this.$localForage.setItem('patientid',this.patientid!='noPatient'?this.patientid:null)
          
        }
       let call= this.$firebase.functions().httpsCallable('loadMapInfo')
       let response=await call({init:true,clinicid:this.clinicid,patientid:this.patientid!='noPatient'?this.patientid:null})
          this.$store.commit('setObjectTo',{what:'typeOfResources',to:response.data.typeOfResources|| {}})
          this.$store.commit('setObjectTo',{what:'resources',to:response.data.resources|| {}})
          this.$store.commit('setWhatTo',{what:'clinic',to:response.data.clinic || {}})
          this.$store.commit('setWhatTo',{what:'lastAccess',to:response.data.lastAccess || null})
         
          this.$f7.views.main.router.navigate('/map')
          
          this.$f7.dialog.close()
    } catch(error){
      this.$f7.dialog.close()
      console.log(error)
      return this.$f7.dialog.alert(error.message,error)
    }
      }
    },
   mounted() {
     let url_string = window.location.href
    let url = new URL(url_string);
    
    let clinicid=url.searchParams.get("c")
    if(clinicid){
      clinicid=clinicid.replaceAll('?','_')
      clinicid=clinicid.replaceAll('¿','_')
      }
      let patientid=url.searchParams.get("p")
      if(patientid){
      patientid=patientid.replaceAll('?','_')
      patientid=patientid.replaceAll('¿','_')
      }
    this.$store.commit('setWhatTo',{what:'clinicid',to:clinicid || null})
       this.$store.commit('setWhatTo',{what:'patientid',to:patientid || null})
     window.history.replaceState({}, document.title, '/');
     
     this.$f7ready((f7) => {
        
        // Call F7 APIs here
      }); 
      
      this.getInfo()
    }
  }
</script>