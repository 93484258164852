<template>
  <f7-page style="width: 100%">
    <f7-navbar title="Resources">
      <f7-nav-right v-if="!callingYelp">
        <f7-link @click="newFraming(true)" icon-material="refresh"></f7-link>
        <f7-link @click="backHome()" icon-material="home"></f7-link>
        <f7-link
          class="searchbar-enable"
          data-searchbar=".searchbar-resources"
          icon-material="search"
        ></f7-link>
        <f7-button popover-open=".popover-filterx">
          Filter
          <f7-icon material="filter_list">
            <f7-badge
              class="badgeTop"
              :color="activeFilters.length > 0 ? 'red' : ''"
              >{{ activeFilters.length }}</f7-badge
            >
          </f7-icon>
        </f7-button>
      </f7-nav-right>
      <f7-searchbar
        ref="searchForResource"
        @searchbar:enable="searchbarOpened = true"
        @searchbar:disable="searchbarOpened = false"
        class="searchbar-resources"
        expandable
        search-container=".resourcesList"
        search-in=".item-title,.item-text"
        :disable-button="true"
      ></f7-searchbar>
    </f7-navbar>

    <div class="comboholder">
      <f7-block class="maperholder">
        <div class="mapresourcedos"></div>
      </f7-block>

      <f7-list class="searchbar-not-found">
        <f7-list-item title="Nothing found"></f7-list-item>
      </f7-list>
      <f7-list class="resourcesList searchbar-found" media-list>
        <f7-list-item
          v-if="filteredResources.length == 0"
          title="No resources match your filters"
          ><f7-button @click="resetFilter()" fill raised slot="after"
            >Reset filters</f7-button
          ></f7-list-item
        >

        <f7-list-item
          v-for="resource in filteredResources"
          :key="resource.placeId"
          name="resourcex-checkbox"
          :title="resource.ginfo.name"
          :subtitle="typeOfResources[resource.type].name"
          :text="resource.ginfo.formatted_address"
        >
          <div
            class="iconBackground"
            :style="{ backgroundColor: typeOfResources[resource.type].color }"
            slot="before-title"
          >
            <f7-icon class="iconRx">{{
              typeOfResources[resource.type].ico.uni
            }}</f7-icon>
          </div>
          <f7-button @click="focusResource(resource)" raised slot="after">
            Details
          </f7-button>
        </f7-list-item>
      </f7-list>
    </div>
    <f7-popover class="popover-filterx inerliimtheight">
      <f7-list>
        <f7-list-item
          checkbox
          v-for="type in sortedResourcesTypes"
          :key="type.id"
          popover-close
          :title="type.name"
          :value="type.id"
          @change="checkActiveFilters"
        >
          <f7-icon
            :style="{
              backgroundColor: type.color,
              borderRadius: '20%',
              padding: '5px',
            }"
            :icon="'fa fa-lg fa-' + type.ico.ico"
            slot="after"
          ></f7-icon>
        </f7-list-item>
      </f7-list>
    </f7-popover>

    <f7-sheet
      class="resourceInfo-sheet"
      :closeByBackdropClick="false"
      :backdrop="false"
      :opened="sheetOpened"
      @sheet:close="resetSheetmodalContent()"
      @sheet:closed="sheetOpened = false"
    >
      <f7-toolbar>
        <div class="right">
          <f7-block
            ><f7-button raised sheet-close>Close details</f7-button></f7-block
          >
        </div>
      </f7-toolbar>
      <!-- Scrollable sheet content rating -->
      <f7-page-content class="bggris">
        <f7-card>
          <f7-card-header><b>Information</b></f7-card-header>
          <f7-card-content>
            <f7-list>
              <f7-list-item
                :header="
                  typeOfResources[detailedResource.type]
                    ? typeOfResources[detailedResource.type].name
                    : ''
                "
                :title="detailedResource.ginfo.name"
              ></f7-list-item>
              <f7-list-item
                v-if="detailedResource.ginfo.formatted_address"
                header="Address"
                :title="detailedResource.ginfo.formatted_address"
              ></f7-list-item>
              <f7-list-item
                v-if="detailedResource.ginfo.international_phone_number"
                title="Phone"
                :after="detailedResource.ginfo.international_phone_number"
              ></f7-list-item>
              <f7-list-item
                v-if="detailedResource.ginfo.rating"
                title="Rating"
                :after="detailedResource.ginfo.rating"
              ></f7-list-item>
              <f7-list-item
                v-if="detailedResource.ginfo.website"
                header="Website"
                :title="detailedResource.ginfo.website"
              ></f7-list-item>
              <f7-list-item divider title="Opening hours"></f7-list-item>
              <f7-list-item
                v-for="(hourd, idx) in openingHoursReceived"
                :key="idx"
                :title="hourd"
              ></f7-list-item>
              <f7-list-item
                v-if="openingHoursReceived.length == 0"
                title="No information on opening hours"
              ></f7-list-item>
            </f7-list>
          </f7-card-content>
        </f7-card>

        <f7-card>
          <f7-card-header
            ><b>Directions</b
            ><f7-button
              @click="requestDirections()"
              v-if="!directionsReceived"
              raised
              >Get directions</f7-button
            >
            <f7-segmented v-else strong tag="p">
              <f7-button
                icon-material="directions_walk"
                @click="requestDirections('WALKING')"
                :active="travelMode == 'WALKING'"
              ></f7-button>
              <f7-button
                icon-material="directions_transit"
                @click="requestDirections('TRANSIT')"
                :active="travelMode == 'TRANSIT'"
              ></f7-button>
              <f7-button
                icon-material="directions_car"
                @click="requestDirections('DRIVING')"
                :active="travelMode == 'DRIVING'"
              ></f7-button>
            </f7-segmented>
          </f7-card-header>
          <f7-card-content v-show="directionsReceived || loadingDirections">
            <p v-if="loadingDirections">
              <f7-preloader color="multi"></f7-preloader> Loading directions...
            </p>
            <f7-block
              v-show="directionsReceived"
              id="howtodirections"
            ></f7-block>
          </f7-card-content>
        </f7-card>

        <f7-card>
          <f7-card-header
            ><b>Photos</b
            ><f7-button @click="requestPhotos()" v-if="!photosReceived" raised
              >Get photos</f7-button
            ></f7-card-header
          >
          <f7-card-content v-if="photosReceived || loadingPhotos">
            <p v-if="loadingPhotos">
              <f7-preloader color="multi"></f7-preloader> Loading photos...
            </p>
            <f7-row v-if="photosReceived">
              <f7-photo-browser
                :photos="photosReceived"
                theme="dark"
                ref="darkphotoviewer"
              ></f7-photo-browser>
              <f7-col
                v-for="(onephoto, idx) in photosReceived"
                :key="idx"
                width="25"
                @click="$refs.darkphotoviewer.open(idx)"
                class="fotosalto"
                :style="
                  'background: url(' +
                  onephoto +
                  '); background-repeat:no-repeat; background-size:cover; background-position:center;'
                "
              ></f7-col>
            </f7-row>
          </f7-card-content>
        </f7-card>
      </f7-page-content>
    </f7-sheet>
    <f7-button popup-open=".newCenter-popup" style="display:none" id="hiddenButton" ></f7-button>
    <f7-popup  class="newCenter-popup">
      <f7-page>
         <f7-navbar title="Changing map center...">
          <f7-nav-right>
            <f7-link popup-close>Close</f7-link>
          </f7-nav-right>
        </f7-navbar>
        <f7-list class="no-margin">
          <f7-list-input 
          id="newCenterInput"
          label="Address/Place"
          type="text"
          input-id="autocomplete_newCenter"
          placeholder="Search address or place name" 
          clear-button
  >
  </f7-list-input>
        </f7-list>
      </f7-page>
    </f7-popup>
  </f7-page>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import loadGoogleMapsApi from "load-google-maps-api";

export default {
  data() {
    return {
      mapHome: { lat: 38.6349591, lng: -90.26496159999999 }, //map centered on BJC HOSPITAL
      homeMarker:null,
      lemapa: null,
      activeFilters: [],
      callingYelp: false,
      markers: {},
      yelpError:false,
      googleMaps: null,
      sheetOpened: false,
      detailedResourceID: null,
      loadingDirections: false,
      directionsRenderer: null,
      directionsReceived: false,
      loadingPhotos: false,
      localResources: {},
      oldCenter: null,
      photosReceived: null,
      openingHoursReceived: [],
      directionsHolder: {},
      enFocoVisible: [],
      travelMode: "DRIVING",
      searchbarOpened: false,
    };
  },
  computed: {
    ...mapState(["typeOfResources", "resources", "clinic","lastAccess","clinicid","patientid"]),
    ...mapGetters(["sortedResources", "sortedResourcesTypes"]),
    filteredResources() {
      // returns array with resources that are not inactive and are inside the map screen
      if (this.searchbarOpened) {
        return this.local_sortedResources;
      }
      let filteredByFrame = this.local_sortedResources.filter((unrecurso) => {
        if (!unrecurso.inactive) {
          return this.enFocoVisible.includes(unrecurso.id);
        }
      });
      if (this.activeFilters.length > 0) {
        return filteredByFrame.filter((unresroucer) => {
          return this.activeFilters.includes(unresroucer.type);
        });
      } else {
        return filteredByFrame;
      }
    },
    local_sortedResources() {
      // returns array of the resources ordered by name
      return Object.values(this.localResources).sort((a, b) => {
        var x = a.ginfo.name.toLowerCase();
        var y = b.ginfo.name.toLowerCase();
        if (x < y) {
          return -1;
        }
        if (x > y) {
          return 1;
        }
        return 0;
      });
    },
    detailedResource() {
      return this.localResources[this.detailedResourceID] || { ginfo: {} };
    },
  },
  watch: {
    filteredResources(newvalue) {
      this.computeMarkersVisibility();
    },
  },
  mounted() {
    this.$f7.dialog.preloader("Loading...");
        if( Object.values(this.clinic).length > 0){
             this.oldCenter=this.mapHome=Object.assign({},{lat:this.clinic.lat,lng:this.clinic.lng})
   
        }else{
          this.oldCenter=this.mapHome
        }
    this.callYelp(this.oldCenter)
      .then(() => {
        this.loadMapWithPin();
        this.$f7.dialog.close();
      })
      .catch((error) => {
        this.$f7.dialog.close();
        console.log(error);
        this.$f7.dialog.alert((error.message, error.code) || error);
      });
  },
  methods: {
    async callYelp(center) {
      // calls a backend functions that returns new resources from yelp near by the new center
      try {
         let yelpError=null
        this.callingYelp=true
      let call= this.$firebase.functions().httpsCallable('callYelp')
      let ladata=await call({
        center:center
      })
      if(ladata.data.error){
        yelpError=true
        let code=ladata.data.error.statusCode
        if(code==429 ||code==500 ||code==504 ){
          this.$f7.dialog.close()
          console.log('statusCode',ladata.data.error.statusCode)
          this.$f7.toast.show({
            text:'Please wait a few seconds more...',
           position: 'center',
            closeTimeout: 3000,
            destroyOnClose: true
          });
          
          setTimeout(()=>{
            if(!code==429 ){
            this.newFraming(true)
            }else{
              console.log('llamando en settieme out 429')
              this.oldCenter=center
             let localResourcesEmpty=Object.keys(this.localResources).length==0
            this.newFraming(localResourcesEmpty)
          }
          },4000)
          
        }else{
        throw new Error (ladata.data.error.message)
        }
      
      }
      if (yelpError) return yelpError;
      Object.keys(this.markers).forEach(markerid=>{
          this.markers[markerid].setMap(null)
      })
        this.markers = {};
        this.oldCenter = center;
        this.localResources = Object.assign({}, this.resources);
        let yelpFiltered = {};
        Object.values(ladata.data.resources).forEach((res) => {
          if (
            !Object.values(this.resources).some(
              (local) =>
                res.ginfo.lat.toFixed(4) == local.ginfo.lat.toFixed(4) &&
                res.ginfo.lng.toFixed(4) == local.ginfo.lng.toFixed(4) &&
                local.ginfo.name == res.ginfo.name
            )
          ) {
            yelpFiltered[res.id] = res;
          }
        });
        this.localResources = Object.assign(this.localResources, yelpFiltered);
        console.log(
          "this.localResources",
          Object.values(this.localResources).length
        );
        return false;
      } catch (error) {
        throw new Error(error);
      }
    },
    computeMarkersVisibility() {
      let markersid = Object.keys(this.markers);
      markersid.forEach((unmarkerid) => {
        if (
          !this.detailedResourceID &&
          ((this.activeFilters.length >= 0 &&
            this.activeFilters.includes(
              this.localResources[unmarkerid].type
            )) ||
            this.activeFilters.length == 0)
        ) {
          this.markers[unmarkerid].setVisible(true);
        } else {
          this.markers[unmarkerid].setVisible(false);
        }
      });
      if (this.detailedResourceID) {
        // create resource on markers and set it on the map if resource is selected from the searchbar and it is not visible on the screen(so the markers was not created yet)
        if (!this.markers[this.detailedResourceID]) {
          this.createOneMarker(this.localResources[this.detailedResourceID]);
        }
        this.markers[this.detailedResourceID].setVisible(true);
      }
    },
    backHome() {
      let homecenter = new this.googleMaps.LatLng(
        this.mapHome.lat,
        this.mapHome.lng
      );
      this.lemapa.panTo(homecenter);
    },
    checkActiveFilters(event) {
      let value = event.target.value;
      if (event.target.checked) {
        this.activeFilters.push(value);
      } else {
        this.activeFilters.splice(this.activeFilters.indexOf(value), 1);
      }
    },
     async newFraming(refresh=false){ // its calls when there is a pinning on the map.  if the center on the new frame is further away than the maXdistance, then it calls the backend functions to get new yelp resources according to the new center of the map 
      if(!this.mapLoading){
        this.$f7.preloader.showIn('.comboholder');
        this.mapLoading=true
      }
      try{
        let yelpError=false
      let newCenter={lat:this.lemapa.getCenter().lat(),lng:this.lemapa.getCenter().lng()}
      if(!this.detailedResourceID&&(this.haversineFormula(newCenter,this.oldCenter)>=5000||refresh)){
        yelpError = await this.callYelp(newCenter)
      }
      console.log('yelpError',yelpError)
      if(yelpError){
       this.$f7.preloader.hideIn('.comboholder');
      this.mapLoading=false
       return 
      }
       this.enFocoVisible=[];
      this.local_sortedResources.forEach(unrecruso => {
         if( this.lemapa.getBounds().contains({lat: unrecruso.ginfo.lat, lng: unrecruso.ginfo.lng}) ) {
          this.enFocoVisible.push(unrecruso.id);
        }
      });
      this.createMarkers()
      this.callingYelp=false
      this.$f7.preloader.hideIn('.comboholder');
      this.mapLoading=false
      }catch(error){
        this.$f7.preloader.hideIn('.comboholder');
        this.mapLoading=false
        console.log(error)
        this.$f7.dialog.alert((error.message,error.code)||error)
      }
    },
    loadMapWithPin() {
      loadGoogleMapsApi({
        key: this.$store.state.gmapsAPIkey,
        libraries: ["places"],
        language: "en",
      })
        .then((googleMaps) => {
          this.googleMaps = googleMaps;
          if (Object.values(this.clinic).length > 0) {
            this.mapHome = Object.assign(
              {},
              { lat: this.clinic.lat, lng: this.clinic.lng }
            );
          }
          this.lemapa = new this.googleMaps.Map(
            document.querySelector(".mapresourcedos"),
            {
              center: this.mapHome,
              zoom: 15,
              streetViewControl: false,
              mapTypeControl: false,
            }
          );
          this.lemapa.setOptions({ minZoom: 12 });
          this.googleMaps.event.addListener(this.lemapa, "idle", () => {
            this.newFraming();
          }); // newFraming is triggered now

          //create home marker
          this.createHomeMarker()
  
          this.directionsRenderer = new this.googleMaps.DirectionsRenderer({
            suppressMarkers: true,
          });
          this.directionsRenderer.setPanel(
            document.getElementById("howtodirections")
          );

          this.autocompleteChangeCenter()

          this.$f7.dialog.close();
        })
        .catch((error) => {
          this.$f7.dialog.close();
          console.error(error);
        });
    },
    autocompleteChangeCenter(){
      let autocomplete = new this.googleMaps.places.Autocomplete(
            /** @type {!HTMLInputElement} */ (
                document.getElementById('autocomplete_newCenter')), {
              componentRestrictions: {'country': 'us'},
            //  bounds: defaultBounds,
            });
            autocomplete.addListener('place_changed', ()=> {
              
            let place = autocomplete.getPlace();
            if (place.geometry) {

            let newCenter={lat:place.geometry.location.lat(),
            lng:place.geometry.location.lng()
            }
            this.mapHome=Object.assign({},newCenter)
            this.homeMarker.setMap(null)
            this.createHomeMarker()
            this.lemapa.panTo(this.mapHome)
            this.$f7.popup.close('.newCenter-popup')
            }
            
            })
    },
    haversineFormula(p1, p2) {
      // returns the maximum distance between to point on the map
      let rad = function (x) {
        return (x * Math.PI) / 180;
      };

      let getDistance = function (p1, p2) {
        let R = 6378137; // Earth’s mean radius in meter
        let dLat = rad(p2.lat - p1.lat);
        let dLong = rad(p2.lng - p1.lng);
        let a =
          Math.sin(dLat / 2) * Math.sin(dLat / 2) +
          Math.cos(rad(p1.lat)) *
            Math.cos(rad(p2.lat)) *
            Math.sin(dLong / 2) *
            Math.sin(dLong / 2);
        let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        let d = R * c;
        return d; // returns the distance in meter
      };
      let distance = getDistance(p1, p2);
      return distance;
    },
    createHomeMarker(){
      this.homeMarker = new this.googleMaps.Marker({
            position: this.mapHome,
            map: this.lemapa,
            zIndex: 99,
            icon: {
              url: "https://maps.google.com/mapfiles/kml/paddle/red-blank.png",
              scaledSize: {
                height: 50,
                width: 50,
              },
              labelOrigin: {
                x: 26,
                y: 15,
              },
            },
            label: {
              fontFamily: "Fontawesome",
              text: "\uf015",
              color: "#FFF",
              fontSize: "18px",
            },
          });
           const contentString =`<a onclick="document.getElementById('hiddenButton').click();">Center map in other place</a>`
          const infowindow = new this.googleMaps.InfoWindow({
            content:contentString
          });
          this.homeMarker.addListener("click", () => {
            infowindow.open(this.lemapa, this.homeMarker);
            this.lemapa.panTo(this.mapHome);
          });
          infowindow.open(this.lemapa, this.homeMarker);
    },
    async checkLastAccess(){
      
      let now=this.$moment().unix()
      if(now-this.lastAccess>=7200){
        try{
      let call= this.$firebase.functions().httpsCallable('loadMapInfo')
       let response=await call({clinicid:this.clinicid,patientid:this.patientid})
          this.$store.commit('setWhatTo',{what:'lastAccess',to:response.data.lastAccess || null})
          }catch(error){
            console.log(error)
            this.$f7.dialog.alert(error.message)
          }
      }
    },
    createMarkers() {
      this.filteredResources.forEach((unrecruso) => {
        if (!unrecruso.inactive && !this.markers[unrecruso.id]&&this.lemapa.getBounds().contains({lat: unrecruso.ginfo.lat, lng: unrecruso.ginfo.lng})) {
          this.createOneMarker(unrecruso);
        }
      });
    },
    createOneMarker(unrecruso) {
      let unicode = this.typeOfResources[unrecruso.type].ico.uni;
      let markerColor = this.typeOfResources[unrecruso.type].color.slice(1);
      let lobje = {
        position: { lat: unrecruso.ginfo.lat, lng: unrecruso.ginfo.lng },
        map: this.lemapa,
        icon: {
          url:
            "https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=|" +
            markerColor,

          labelOrigin: {
            x: 15,
            y: 12,
          },
          scaledSize: new google.maps.Size(30, 40),
        },
        label: {
          fontFamily: "Fontawesome",
          text: unicode,
          color: "black",
          fontSize: "18px",
        },
      };

      this.markers[unrecruso.id] = new this.googleMaps.Marker(lobje)
      this.markers[unrecruso.id].addListener("click", () => {
        this.focusResource(unrecruso);
      });
    },
    resetFilter() {
      this.activeFilters = [];
    },
    focusResource(resource) {
      this.$refs.searchForResource.f7Searchbar.disable();
      this.detailedResourceID = resource.id;
      if (!this.directionsHolder[resource.id]) {
        this.directionsHolder[resource.id] = {};
      }
      this.computeMarkersVisibility();
      let resourceCenter = new this.googleMaps.LatLng(
        resource.ginfo.lat,
        resource.ginfo.lng
      );
      this.lemapa.panTo(resourceCenter);
      this.lemapa.setZoom(16);
      this.sheetOpened = true;
      if (resource.ginfo.place_id) this.requestOpeningHours(resource);
    },
    requestDirections(cual = "DRIVING") {
      this.travelMode = cual;
      if (!this.directionsHolder[this.detailedResourceID][cual]) {
        this.loadingDirections = true;
        let request = {
          origin: new this.googleMaps.LatLng(
            this.mapHome.lat,
            this.mapHome.lng
          ),
          destination: this.detailedResource.ginfo.formatted_address,
          travelMode: cual,
        };
        let directionsService = new this.googleMaps.DirectionsService();
        directionsService.route(request, (result, status) => {
          this.loadingDirections = false;
          if (status !== "OK") {
            this.loadingDirections = false;
            return this.$f7.dialog.alert(status);
          } else {
            this.directionsHolder[this.detailedResourceID][cual] = result;
            this.drawdirecitons(result);
          }
          this.loadingDirections = false;
        });
      } else {
        this.drawdirecitons(
          this.directionsHolder[this.detailedResourceID][cual]
        );
      }
    },
    drawdirecitons(result) {
      this.directionsReceived = true;
      this.directionsRenderer.setMap(this.lemapa);
      this.directionsRenderer.setDirections(result);
    },
    requestPhotos() {
      this.loadingPhotos = true;
      let request2 = {
        placeId: this.detailedResource.ginfo.place_id,
        fields: ["photo"],
      };
      let service = new this.googleMaps.places.PlacesService(this.lemapa);
      service.getDetails(request2, (place, status) => {
        this.loadingPhotos = false;
        if (status !== "OK") {
          return this.$f7.dialog.alert(status);
        } else {
          let locphots = place.photos || [];
          this.photosReceived = locphots.map((x) => x.getUrl());
        }
      });
    },
    requestOpeningHours(resource) {
      this.openingHoursReceived = [];
      let request2 = {
        placeId: resource.ginfo.place_id,
        fields: ["opening_hours"],
      };
      let service = new this.googleMaps.places.PlacesService(this.lemapa);
      service.getDetails(request2, (place, status) => {
        if (status !== "OK") {
          console.error("error loading opening hours", place);
          // return this.$f7.dialog.alert(status);
        } else {
          this.openingHoursReceived =
            place.opening_hours && place.opening_hours.weekday_text
              ? place.opening_hours.weekday_text
              : [];
          if (this.openingHoursReceived.length > 0) {
            this.$store.commit("updateOpeningHoursToResource", {
              id: resource.id,
              value: this.openingHoursReceived,
            });
          }
        }
      });
    },

    resetSheetmodalContent() {
      this.detailedResourceID = null;
      this.directionsRenderer.setMap(null);
      document.getElementById("howtodirections").innerHTML = "";
      this.directionsReceived = false;
      this.photosReceived = null;
      this.computeMarkersVisibility();
    },
  },
};
</script>
<style >
.comboholder {
  height: 100%;
}
.maperholder {
  height: 50%;
  max-height: 50%;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
.resourcesList {
  height: 50%;
  max-height: 50%;
  overflow: auto;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
.resourcesList .item-media label {
  margin: 0 auto;
  margin-top: 15px;
}

.resourcesList li {
  border-bottom: 1px solid #cecece;
}

.mapresourcedos {
  height: 100%;
}
.iconBackground {
  margin-right: 10px;
  border-radius: 50%;
  width: 26px;
  height: 26px;
}
.inerliimtheight .popover-inner {
  max-height: 50vh;
  overflow-y: auto;
}
.inerliimtheight {
  width: 400px;
}

.resourceInfo-sheet.sheet-modal {
  border-top: 2px solid #559cfd;
  height: 58%;
}
.fotosalto {
  height: 100px;
  background-color: #cecece;
  /* -webkit-background-size:cover; -moz-background-size:cover; -o-background-size:cover; */
  margin-bottom: 16px;
}

.popup.photo-browser-popup.photo-browser-standalone {
  z-index: 99999 !important;
}
.iconRx {
  font-family: Fontawesome;
  left: 5px;
}
.badgeTop {
  top: -7px !important;
}
.gm-style .gm-style-iw-c a span{ /* icon from the map dont display link to google maps*/ 
  display: none !important;
}
.newCenter-popup{
  height: 160px !important;
}
div.pac-container.pac-logo {
  display: inline-block; 
    z-index: 1000000 !important;
}
</style>